h2 {
  margin-left: 24px;
  margin-bottom: 16px;
}

.dashboard-report-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 24px;
  column-gap: 16px;
  row-gap: 16px;
}

.dashboard-report-section > .box {
  background-color: #efefef;
  padding: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 5px;
}

.box-value {
  font-size: 32px;
  font-weight: bold;
}

.box-text {
  font-size: 14px;
  opacity: 0.7;
  text-align: center;
}
