#dashboard-control {
  padding: 12px 32px;
  background-color: #eee;
}

.control-group {
  display: inline-block;
  margin-right: 16px;
}

.control-group + .control-group {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 16px;
}

.control-group > span {
  margin-right: 6px;
}

input,
select {
  font-size: 14px;
  border-radius: 3px;
  padding: 4px 10px;
  border: 1px solid #ddd;
}

.control-group input {
  width: 80px;
}

hr {
  margin-top: 32px;
  margin-bottom: 24px;
}
