.chart {
  box-sizing: border-box;
  width: 33%;
  float: left;
  padding: 8px 32px;
}

.chart > h3 {
  margin-bottom: 6px;
}

.chart-subtitle {
  margin-bottom: 18px;
}

@media (max-width: 1200px) {
  .chart {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .chart {
    width: 100%;
  }
}
